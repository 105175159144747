<template>
    <a class="btn-floating" :style="showingBottomNav ? 'bottom: 4.4rem;' : 'bottom: 1rem;'" @click="addDevice">
        <img src="img/plus.png" width="20" style="display:block; margin:auto; padding-top: 20px">
    </a>
</template>

<script>
    import {isMobileNavigationMode} from "../../utilities/mobile.navigation.utility";

    export default {
        name: "FloatingActionButton",

        methods: {
            addDevice() {
                this.$root.$emit('bv::show::modal', 'addDeviceModal');
            }
        },

        computed: {
            showingBottomNav: function () {
                return isMobileNavigationMode(this.$platform);
            }
        },
    }
</script>

<style scoped>
    .btn-floating {
        position: absolute;
        /*bottom: 4rem;*/
        right: 0.7rem;
        width: 3.6rem;
        height: 3.6rem;
        line-height: 3.6rem;
        border-radius: 50%;
        cursor: pointer;
        box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
        background-color: var(--fab);
    }
</style>
