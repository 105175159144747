<template>
    <nav class="sidebar-nav" style="background: white;">
        <ul class="nav" style="padding-top: 3px;">
            <template v-for="(item, index) in navItems">
                <template v-if="item.title">
                    <SidebarNavTitle :key="index" :name="item.name" :classes="item.class" :wrapper="item.wrapper" style="color: #868ba1"/>
                </template>
                <template v-else-if="item.divider">
                    <SidebarNavDivider :key="index" :classes="item.class"/>
                </template>
                <template v-else-if="item.label">
                    <SidebarNavLabel :key="index" :name="item.name" :url="item.url" :icon="item.icon" :label="item.label" :classes="item.class"/>
                </template>
                <template v-else>
                    <template v-if="item.children">
                        <!-- First level dropdown -->
                        <SidebarNavDropdown :key="index" :name="item.name" :url="item.url" :icon="item.icon">
                            <template v-for="(childL1, index1) in item.children">
                                <template v-if="childL1.children">
                                    <!-- Second level dropdown -->
                                    <SidebarNavDropdown :key="index1" :name="childL1.name" :url="childL1.url" :icon="childL1.icon">
                                        <li :key="index2" class="nav-item" v-for="(childL2, index2) in childL1.children">
                                            <SidebarNavLink :name="childL2.name" :url="childL2.url" :icon="childL2.icon" :badge="childL2.badge"
                                                            :variant="childL2.variant" :attributes="childL2.attributes"/>
                                        </li>
                                    </SidebarNavDropdown>
                                </template>
                                <template v-else>
                                    <SidebarNavItem :key="index1" :classes="item.class">
                                        <SidebarNavLink :name="childL1.name" :url="childL1.url" :icon="childL1.icon" :badge="childL1.badge"
                                                        :variant="childL1.variant" :attributes="childL1.attributes"/>
                                    </SidebarNavItem>
                                </template>
                            </template>
                        </SidebarNavDropdown>
                    </template>
                    <template v-else>
                        <SidebarNavItem :key="index" :classes="item.class">
                            <MySidebarNavLink :name="item.name" :url="item.url" :icon="item.icon" :badge="item.badge" :variant="item.variant"
                                            :attributes="item.attributes" class="sidebar-item"/>
                        </SidebarNavItem>
                    </template>
                </template>
            </template>
        </ul>
        <slot></slot>
    </nav>
</template>

<script>
    import {SidebarNavDivider, SidebarNavDropdown, SidebarNavItem, SidebarNavLabel, SidebarNavLink, SidebarNavTitle} from '@coreui/vue'
    import MySidebarNavLink from "./MySidebarNavLink";

    export default {
        name: 'MySidebarNav',

        components: {
            SidebarNavDivider,
            SidebarNavDropdown,
            SidebarNavLink,
            SidebarNavTitle,
            SidebarNavItem,
            SidebarNavLabel,
            MySidebarNavLink
        },

        data() {
            return {
                firstClick: true
            }
        },

        computed: {
            navItems: function() {
                return [
                    {
                        title: true,
                        name: this.$t('sidebar.navigation'),
                        class: '',
                        wrapper: {
                            element: '',
                            attributes: {}
                        }
                    },
                    {
                        name: this.$t('devices.header'),
                        url: '/dashboard',
                        icon: 'fa fa-home'
                    },
                    {
                        name: this.$t('templates.header'),
                        url: '/templates',
                        icon: 'fa fa-clone'
                    },
                    {
                        name: this.$t('documents.header'),
                        url: '/documents',
                        icon: 'fa fa-book',
                    },
                    {
                        name: this.$t('settings.header'),
                        url: '/settings',
                        icon: 'fa fa-cog'
                    },
                    {
                        name: this.$t('help.header'),
                        url: '/help',
                        icon: 'fa fa-question-circle    '
                    }
                ]
            },
            psSettings: () => {
                // ToDo: find better rtl fix
                return {
                    maxScrollbarLength: 200,
                    minScrollbarLength: 40,
                    suppressScrollX: getComputedStyle(document.querySelector('html')).direction !== 'rtl',
                    wheelPropagation: false,
                    interceptRailY: styles => ({...styles, height: 0})
                }
            }
        },
        methods: {
            scrollHandle(evt) {
                // console.log(evt)
            }
        }
    }
</script>

<style scoped lang="css">
    .scroll-area {
        position: absolute;
        height: 100%;
        margin: auto;
    }

    .sidebar-item {
        color: #868e96;
    }

    .sidebar-item:hover {
        color: #343a40;
        background: #dee2e6;
    }

    .sidebar .nav-link.active {
        color: #343a40;
        background: #f8f9fa;
    }
</style>





























