<template>
    <div>
        <div v-if="!shouldShowBottomNav && !(this.basePath === 'device' || this.basePath === 'edit-device')" class="p-2 header-container"
             :style="headerClass">
            <div v-if="this.basePath === 'dashboard'">
                <span style="font-size: 1.15rem">{{ $t('devices.header') }}</span>
            </div>
            <div v-else-if="this.basePath === 'templates'">
                <span style="font-size: 1.15rem">{{ $t('templates.header') }}</span>
            </div>
            <div v-else-if="this.basePath === 'documents'">
                <span style="font-size: 1.15rem">{{ $t('documents.header') }}</span>
            </div>
            <div v-else-if="this.basePath === 'settings'">
                <span style="font-size: 1.15rem">{{ $t('settings.header') }}</span>
            </div>
            <div v-else-if="this.basePath === 'help'">
                <span style="font-size: 1.15rem">{{ $t('help.header') }}</span>
            </div>
        </div>

        <div v-if="this.basePath === 'device' || this.basePath === 'edit-device'" class="p-2 header-container" :style="headerClass">
            <div>
                <span style="font-size: 1.15rem">{{ deviceName }}</span>

                <span v-if="this.basePath === 'edit-device'" style="font-size: 1rem">
                        <b-btn class="mx-1 small-button" @click="viewVirtualDevice()">
                            <i class="icon-magnifier small-icon"></i>
                        </b-btn>
                </span>

                <span v-if="this.basePath === 'device'" style="font-size: 1rem">
                        <b-btn class="mx-1 small-button" @click="editVirtualDevice()">
                            <i class="icon-wrench small-icon"></i>
                        </b-btn>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: "Header",

        props: ['shouldShowBottomNav'],

        computed: {
            ...mapGetters({
                getDeviceName: 'virtualDevice/deviceName',
                getMacAddress: 'virtualDevice/macAddress',
                getUiVersion: 'virtualDevice/uiVersion',
                getUnlinked: 'virtualDevice/unlinked',
            }),

            deviceName: function () {
                return this.getDeviceName(this.$route.params.id);
            },

            basePath: function () {
                let path = this.$route.path;
                return path.split('/')[1];
            },

            headerClass: function () {
                return {
                    marginTop: this.$platform === 'ios' ? 'env(safe-area-inset-top)' : '0px'
                }
            }
        },

        data() {
            return {
                previousRequestFinished: true,
                timeout: null
            }
        },

        //This header component is part of the layout so it is created only once
        //That's why this timeout always run and only pings on specific paths
        created() {
            this.setPingTimeout();
        },

        beforeDestroy() {
            clearTimeout(this.timeout);
        },

        methods: {
            viewVirtualDevice() {
                this.$router.push({name: 'device', params: {id: this.$route.params.id}});
            },

            editVirtualDevice() {
                this.$router.push({name: 'edit-device', params: {deviceId: this.$route.params.id}});
            },

            uiVersion: function () {
                return this.getUiVersion(this.$route.params.id);
            },

            isEmpty: function () {
                return this.getMacAddress(this.$route.params.id) === null;
            },

            isUnlinked: function () {
                return this.getUnlinked(this.$route.params.id);
            },

            async setPingTimeout() {
                this.timeout = setTimeout(async () => {
                    try {
                        await this.pingDevice();
                    } catch (err) {
                        console.log(err);
                    }

                    this.setPingTimeout();
                }, 5000);
            },

            async pingDevice() {
                if (this.basePath === 'device' || this.basePath === 'edit-device') {
                    if (!this.isEmpty() && !this.isUnlinked()) {
                        let uiVersion = this.uiVersion();
                        uiVersion = parseInt(uiVersion.split('tdc_v')[1]);
                        let path = uiVersion > 2 ? '/ping-v2' : '/ping';

                        let response = await this.$axios.get(path, {
                            params: {
                                id: this.$route.params.id
                            }
                        });

                        //In case you are not on the same route when you initialized the request
                        if (this.basePath === 'device' || this.basePath === 'edit-device') {
                            this.$store.commit('virtualDevice/setIsOnline', {deviceId: this.$route.params.id, isOnline: response.data.isOnline});
                        }
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .header-container {
        background: white;
        box-shadow: 10px 10px 20px rgba(0, 0, 0, .08);
        border-left: 1px solid #f3f3f3;
        max-height: 43px;
    }

    .small-button {
        padding: 0rem 0.3rem;
        margin-bottom: 0.2rem;
        box-shadow: none;
        background: white;
        border-color: var(--primary);
        outline: none !important;
    }

    .small-button:focus {
        background: white !important;
        border-color: var(--primary) !important;
    }

    .small-button:after {
        border: none !important;
        outline: none !important;
    }

    .small-icon {
        color: var(--primary);
    }
</style>
