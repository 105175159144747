<template>
    <b-nav v-if="show" ref="bottomNav" tabs fill align="center" class="bottom-nav-container">
        <b-nav-item to="/dashboard" link-classes="bottom-nav-item" active-class="bottom-nav-item-active">
            <i class="fa fa-home bottom-nav-icon text-center">
                <br><span class="bottom-nav-label">{{ $t('devices.header') }}</span>
            </i>
        </b-nav-item>

        <b-nav-item to="/templates" link-classes="bottom-nav-item" active-class="bottom-nav-item-active">
            <i class="fa fa-clone bottom-nav-icon text-center">
                <br><span class="bottom-nav-label">{{ $t('templates.header') }}</span>
            </i>
        </b-nav-item>

        <b-nav-item to="/documents" link-classes="bottom-nav-item" active-class="bottom-nav-item-active">
            <i class="fa fa-book bottom-nav-icon text-center">
                <br><span class="bottom-nav-label">{{ $t('documents.header') }}</span>
            </i>
        </b-nav-item>

        <b-nav-item to="/help" link-classes="bottom-nav-item" active-class="bottom-nav-item-active">
            <i class="fa fa-question-circle bottom-nav-icon text-center">
                <br><span class="bottom-nav-label">{{ $t('help.header') }}</span>
            </i>
        </b-nav-item>

        <b-nav-item to="/settings" link-classes="bottom-nav-item" active-class="bottom-nav-item-active">
            <i class="fa fa-cog bottom-nav-icon text-center">
                <br><span class="bottom-nav-label">{{ $t('settings.header') }}</span>
            </i>
        </b-nav-item>
    </b-nav>
</template>

<script>
    import {Plugins} from '@capacitor/core';

    const {Keyboard} = Plugins;

    export default {
        name: "BottomNavigation",

        data() {
            return {
                show: true
            }
        },

        created() {
            this.$root.$on('hideBottomNav', () => {
                setTimeout(() => {
                    this.show = false;
                }, 150);
            });

            /**
             * Even though the bottom navigation bar should be hidden by an event emitted by a component
             * this is left here in case it is forgotten to do so
             */
            window.addEventListener('keyboardWillShow', (e) => {
                this.show = false;
            });

            window.addEventListener('keyboardWillHide', () => {
                this.show = true;
            });

            Keyboard.setAccessoryBarVisible({isVisible: true});
        }
    }
</script>

<style scoped>
    .bottom-nav-container {
        position: fixed;
        width: 100%;
        /*height: 8vh;*/
        bottom: 0;
        z-index: 9999;
        background: white;
        border-bottom: none;
        border-top: 1px solid #e1e1e1;
    }

    .bottom-nav-item:active {
        border-color: transparent;
    }

    .bottom-nav-item {
        /*padding: 0.9rem 0.8rem;*/
        padding: 1vh 1vw;
        border-color: transparent;
    }

    .bottom-nav-icon {
        border: none;
        font-size: 130%;
    }

    .bottom-nav-label {
        border: none;
        font-size: 70%;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    }

    .bottom-nav-item-active {
        color: var(--bottomNav) !important;
        border: 1px solid transparent !important;
    }

    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
        border-color: white;
    }
</style>