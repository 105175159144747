<template>
    <AppHeaderDropdown right no-caret>
        <template slot="header">
            <img v-if="$OEM === 'pleion'" src="../../../public/img/pleion/pleion-avatar.png" class="img-avatar" alt="avatar"/>
            <img v-else src="../../../public/img/things/things-avatar.png" class="img-avatar" alt="avatar"/>
        </template>

        <template slot="dropdown">
            <b-dropdown-header tag="div" class="text-center" style="background: white; border-bottom: 1px solid rgb(243, 243, 243);">
                <strong>Account</strong>
            </b-dropdown-header>
            <b-dropdown-item-button @click="$router.push('/settings')"><i class="fa fa-cog"/>{{ $t('settings.header') }}</b-dropdown-item-button>
            <b-dropdown-item @click="logout"><i class="fa fa-sign-out"/>{{ $t('settings.logout') }}</b-dropdown-item>
        </template>
    </AppHeaderDropdown>
</template>

<script>
    import {mapActions} from "vuex";
    import {HeaderDropdown as AppHeaderDropdown} from '@coreui/vue'

    export default {
        name: 'DefaultHeaderDropdownAccnt',

        components: {
            AppHeaderDropdown
        },

        methods: {
            ...mapActions('auth', [
                'logout'
            ])
        }
    }
</script>

<style>
    .dropdown-menu {
        min-width: 180px;
        border: none;
        border-radius: 0;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
    }
</style>
