<template>
    <div ref="defaultContainer" class="app" style="background: #f8f9fa;">
        <AppHeader fixed :style="headerClass">
            <SidebarToggler v-if="!showingBottomNav" style="outline: none;" class="d-lg-none" display="md" mobile/>

            <b-link :style="brandImgClass" class="navbar-brand" to="/dashboard">
                <img v-if="$OEM === 'pleion'" class="navbar-brand-full" src="../../../public/img/pleion/pleion-logo.svg" width="140" height="38"
                     alt="Pleion Logo">
                <img v-else class="navbar-brand-full" src="../../../public/img/things/things-logo.svg" width="140" height="38" alt="Things Logo">

                <img class="navbar-brand-minimized" src="../../../public/img/icons/safari-pinned-tab.svg" width="30" height="30" alt="Things Logo">
            </b-link>

            <SidebarToggler v-if="!showingBottomNav" style="outline: none;" class="d-md-down-none" display="lg"/>

            <b-navbar-nav v-if="!showingBottomNav" class="ml-auto">
                <DefaultHeaderDropdownAccnt/>
            </b-navbar-nav>
        </AppHeader>

        <vue-progress-bar :style="progressBarStyle"/>

        <div ref="appBody" class="app-body" :style="appBodyStyle">
            <AppSidebar v-if="!showingBottomNav" fixed>
                <SidebarHeader/>
                <div :style="sidebarDividerClass"/>
                <MySidebarNav/>
            </AppSidebar>

            <main ref="main" class="main">
                <Header :should-show-bottom-nav="showingBottomNav"/>
                <NotificationsHeader/>

                <div class="container-fluid p-0" :style="mainContainerStyle">
                    <router-view v-if="showingBottomNav"
                                 :class="[routeChangeOrder > 0 ? bottomNavSlideLeft : '', routeChangeOrder < 0 ? bottomNavSlideRight : '']">
                    </router-view>
                    <router-view v-else class="animated fadeIn"/>
                </div>
            </main>
        </div>

        <floating-action-button v-if="$router.currentRoute.path === '/dashboard'"/>

        <bottom-navigation ref="bottomNav" v-if="showingBottomNav"/>
    </div>

</template>

<script>
    import {
        Header as AppHeader,
        SidebarToggler,
        Sidebar as AppSidebar,
        SidebarHeader,
        SidebarMinimizer,
        Footer as TheFooter
    } from '@coreui/vue'
    import DefaultHeaderDropdownAccnt from './DefaultHeaderDropdownAccnt'
    import MySidebarNav from './MySidebarNav';
    import Header from './Header';
    import NotificationsHeader from './NotificationsHeader';
    import BottomNavigation from "./BottomNavigation";
    import FloatingActionButton from "./FloatingActionButton";
    import {isMobileNavigationMode} from "@/utilities/mobile.navigation.utility";

    export default {
        name: 'full',

        components: {
            Header,
            NotificationsHeader,
            AppHeader,
            AppSidebar,
            TheFooter,
            DefaultHeaderDropdownAccnt,
            SidebarToggler,
            SidebarHeader,
            MySidebarNav,
            SidebarMinimizer,
            BottomNavigation,
            FloatingActionButton
        },

        data() {
            return {
                bottomNavBreakpointFlag: true,
                sidebarNavBreakpointFlag: true,
                routeChangeOrder: 0,
                bottomNavSlideLeft: {
                    animated: true,
                    slideInLeft: true,
                    slideAnimationDuration: true
                },
                bottomNavSlideRight: {
                    animated: true,
                    slideInRight: true,
                    slideAnimationDuration: true
                }
            }
        },

        computed: {
            progressBarStyle: function () {
                return {
                    top: this.$platform === 'ios' ? 'calc(env(safe-area-inset-top) + 55px)' : '55px'
                }
            },

            appBodyStyle: function () {
                return {
                    maxHeight: this.$platform === 'ios' ? 'calc(100vh - env(safe-area-inset-top) + 55px)' : 'calc(100vh - 55px)',
                    overflowY: 'overlay',
                    webkitOverflowScrolling: 'touch'
                }
            },

            mainContainerStyle: function () {
                return {
                    marginTop: this.$platform === 'ios' ? 'env(safe-area-inset-top)' : '0px'
                }
            },

            headerClass: function () {
                return {
                    height: this.$platform === 'ios' ? 'calc(env(safe-area-inset-top) + 55px)' : '55px',
                    paddingTop: this.$platform === 'ios' ? 'env(safe-area-inset-top)' : '0px',
                    borderBottom: '1px solid #f3f3f3'
                }
            },

            brandImgClass: function () {
                return {
                    //Do not include ipads
                    paddingTop: (this.$platform === 'ios' && window.innerWidth <= 425)
                        ? 'calc(env(safe-area-inset-top) + 24px)' : '0px'
                }
            },

            sidebarDividerClass: function () {
                return {
                    paddingTop: this.$platform === 'ios' ? 'calc(env(safe-area-inset-top) + 12px)' : '0px'
                }
            },

            showingBottomNav: function () {
                return isMobileNavigationMode(this.$platform);
            }
        },

        watch: {
            '$route'(to, from) {
                let routeOrder = ['dashboard', 'templates', 'documents', 'help', 'settings'];
                let toOrder = routeOrder.indexOf(to.path.split('/')[1]);
                let fromOrder = routeOrder.indexOf(from.path.split('/')[1]);
                this.routeChangeOrder = fromOrder - toOrder;
                this.$refs.appBody.scrollTop = 0; //scroll to top when going on a new route
            }
        },

        mounted() {
            if (this.showingBottomNav && this.$refs.bottomNav !== undefined) {
                this.$refs.defaultContainer.style.maxHeight = (window.innerHeight - this.$refs.bottomNav.$el.clientHeight) + 'px';
                this.$refs.defaultContainer.style.minHeight = 0;
            }
        }
    }
</script>

<style scoped>
    .slideAnimationDuration {
        animation-duration: 0.15s;
    }
</style>