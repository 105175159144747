<template>
    <div style="box-shadow: 0px 10px 20px rgba(0, 0, 0, .08);">
        <b-alert fade v-if="!hasInternetConnection" show variant="warning" style="border-radius: 0;">
            {{ $t('notificationsHeader.offlineMsg') }}
        </b-alert>

        <div v-if="this.basePath === 'device' || this.basePath === 'edit-device'">
            <b-alert fade v-if="isEmpty && !isUnlinked" show variant="primary" style="border-radius: 0;">
                <span id="emptyInfo"
                      style="font-size: 0.875rem; text-decoration:underline; text-decoration-style: dashed; text-underline-position: under; cursor: default;">
                    {{ $t('notificationsHeader.sampleData') }}
                </span>

                <b-tooltip target="emptyInfo" placement="bottom" triggers="hover focus" :title="$t('notificationsHeader.sampleDataTooltip')"/>
            </b-alert>
            <b-alert fade v-else-if="isUnlinked" show variant="primary" style="border-radius: 0;">
                <span id="unlinkedInfo"
                      style="font-size: 0.875rem; text-decoration:underline; text-decoration-style: dashed; text-underline-position: under; cursor: default;">
                    {{ $t('notificationsHeader.deviceNotLinked') }}
                </span>

                <b-tooltip target="unlinkedInfo" placement="bottom" triggers="hover focus" :title="$t('notificationsHeader.deviceNotLinkedTooltip')"/>
            </b-alert>
            <b-alert fade v-else-if="hasInternetConnection && !isOnline" show variant="warning" style="border-radius: 0;">
                {{ $t('notificationsHeader.deviceOffline') }}
            </b-alert>
            <b-alert fade v-else-if="parseInt(errors.e_8)" show variant="warning" style="border-radius: 0;">
                {{ $t('notificationsHeader.manualMode') }}
            </b-alert>
            <!-- Added extra check for sensor defective because devices with older firmware version do not send the EW flag repeatedly  -->
            <b-alert fade v-else-if="parseInt(errors.e_1) || sensorDefective" show variant="danger" style="border-radius: 0;">
                {{ $t('notificationsHeader.sensorDefective') }}
            </b-alert>
            <b-alert fade v-else-if="parseInt(errors.e_2)" show variant="danger" style="border-radius: 0;">
                {{ $t('notificationsHeader.systemProtection') }}
            </b-alert>
            <b-alert fade v-else-if="parseInt(errors.e_3)" show variant="warning" style="border-radius: 0;">
                {{ $t('notificationsHeader.collectorProtection') }}
            </b-alert>
            <b-alert fade v-else-if="parseInt(errors.e_4)" show variant="warning" style="border-radius: 0;">
                {{ $t('notificationsHeader.recoolingProtection') }}
            </b-alert>
            <b-alert fade v-else-if="parseInt(errors.e_5)" show variant="warning" style="border-radius: 0;">
                {{ $t('notificationsHeader.frostProtection') }}
            </b-alert>
            <b-alert fade v-else-if="parseInt(errors.e_6)" show variant="warning" style="border-radius: 0;">
                {{ $t('notificationsHeader.seizeProtection') }}
            </b-alert>
            <b-alert fade v-else-if="parseInt(errors.e_7)" show variant="warning" style="border-radius: 0;">
                {{ $t('notificationsHeader.storageAlarm') }}
            </b-alert>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: "NotificationsHeader",

        data() {
            return {
                hasInternetConnection: navigator.onLine
            }
        },

        computed: {
            ...mapGetters({
                getDeviceName: 'virtualDevice/deviceName',
                getDeviceData: 'virtualDevice/deviceData',
                getMacAddress: 'virtualDevice/macAddress',
                getIsOnline: 'virtualDevice/isOnline',
                getUiVersion: 'virtualDevice/uiVersion',
                getUnlinked: 'virtualDevice/unlinked',
                getParameters: 'virtualDevice/parameters',
                getDeviceErrors: 'virtualDevice/deviceErrors'
            }),

            parameters: function () {
                return this.getParameters(this.$route.params.id);
            },

            errors: function () {
                return this.getDeviceErrors(this.$route.params.id);
            },

            isOnline: function () {
                return this.getIsOnline(this.$route.params.id);
            },

            isEmpty: function () {
                return this.getMacAddress(this.$route.params.id) === null;
            },

            isUnlinked: function () {
                return this.getUnlinked(this.$route.params.id);
            },

            basePath: function () {
                let path = this.$route.path;
                return path.split('/')[1];
            },

            isManualMode: function () {
                return parseInt(this.getParameters(this.$route.params.id)['p_72'].value);
            },

            sensorDefective: function () {
                let data = this.getDeviceData(this.$route.params.id);

                if (data) {
                    return data.sens_1 == 999 || data.sens_2 == 999 || data.sens_3 == 999 || data.sens_4 == 999
                } else return false;
            }
        },

        created() {
            window.addEventListener('online', this.setInternetConnectionTrue);
            window.addEventListener('offline', this.setInternetConnectionFalse);
        },

        destroyed() {
            window.removeEventListener('online', this.setInternetConnectionTrue);
            window.removeEventListener('offline', this.setInternetConnectionFalse);
        },

        methods: {
            setInternetConnectionTrue() {
                this.hasInternetConnection = true;
            },

            setInternetConnectionFalse() {
                this.hasInternetConnection = false;
            }
        }
    }
</script>

<style scoped>

</style>