function isMobileNavigationMode(platform) {
    return platform === 'android' || platform === 'ios';
}

function isMobile() {
    return window.screen.width <= 576;
}

export {
    isMobileNavigationMode,
    isMobile
}